<section class="body">
    <header class="header" (escapeDialog)="handleCloseDialog()">
        <div class="header-title">
            <span class="title-prefix">{{ title }}</span>
        </div>
    </header>
    <div class="body-wrapper">
        <bdr-checkbox
            title="Maestros"
            caption="Maestros"
            [(model)]="reloads['masterdata']"
        ></bdr-checkbox>

        <br />
        <bdr-checkbox title="Inventario" caption="Inventario" [(model)]="reloads['inventory']">
        </bdr-checkbox>
        <br />
        <bdr-checkbox title="Inversiones" caption="Inversiones" [(model)]="reloads['investments']">
        </bdr-checkbox>
        <br />
        <bdr-checkbox
            title="Formularios"
            caption="Formularios"
            [(model)]="reloads['forms']"
        >
        </bdr-checkbox>
        <br />
        <bdr-checkbox
            title="Informe de instalaciones declaradas"
            caption="Informe de instalaciones declaradas"
            [(model)]="reloads['installations']"
        >
        </bdr-checkbox>
        <br />
        <bdr-checkbox
            title="Cuadre de inversiones"
            caption="Cuadre de inversiones"
            [(model)]="reloads['investments-adjustment']"
        >
        </bdr-checkbox>
        <br />
        <bdr-checkbox
            title="Formularios CNMC"
            caption="Formularios CNMC"
            [(model)]="reloads['cnmc']"
        >
        </bdr-checkbox>
    </div>
</section>

<footer class="footer-wrapper">
    <div class="footer">
        <div class="footer-actions">
            <bdr-button class="btn-save" size="small" (click)="handleCloseDialog()"
                >Cancelar</bdr-button
            >
            <bdr-button class="btn-save" primary size="small" (click)="handleReloadSheets()"
                >Recargar</bdr-button
            >
        </div>
    </div>
</footer>
