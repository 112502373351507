import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'bdr-button',
  templateUrl: './bdr-button.component.html',
  styleUrls: ['./bdr-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BdrButtonComponent implements OnInit {
  @Input() primary: string;
  @Input() submit: string;
  @Input() disabled: boolean;
  @Input() size: string;
  @Input() context: string;
  @Input() title: string;
  @Input() color: string;

  _primary: boolean;
  _dialog: boolean;
  _submit: boolean;

  constructor() { }

  ngOnInit() {
    this._dialog = this.context === 'dialog';
    this._primary = typeof this.primary === 'string';
    this._submit = typeof this.submit === 'string';
  }
}
