<section class="body">
  <div>
    <h1>{{ notification.detail.detail_message }}</h1>

    <div *ngIf="notification.detail.detail_table" style="height: 800px; overflow-y: scroll">
      <table
        style="width: 100%"
        _ngcontent-hvj-c203=""
        mat-table=""
        class="mat-table cdk-table mat-elevation-z8"
        role="grid"
      >
        <thead role="rowgroup">
          <tr
            _ngcontent-hvj-c203=""
            role="row"
            mat-header-row=""
            class="mat-header-row cdk-header-row ng-star-inserted"
          >
            <th
              _ngcontent-hvj-c203=""
              role="columnheader"
              mat-header-cell=""
              *ngFor="let header of notification.detail.detail_table.headers"
              class="mat-header-cell cdk-header-cell cdk-column-position mat-column-position ng-star-inserted"
            >
              {{ header }}
            </th>
          </tr>
        </thead>
        <tbody role="rowgroup">
          <tr
            *ngFor="let datas of notification.detail.detail_table.data"
            _ngcontent-hvj-c203=""
            role="row"
            mat-row=""
            class="mat-row cdk-row ng-star-inserted"
          >
            <td
              _ngcontent-hvj-c203=""
              role="gridcell"
              mat-cell=""
              class="mat-cell cdk-cell cdk-column-position mat-column-position ng-star-inserted"
              *ngFor="let data of datas"
            >
              {{ isObject(data) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <footer *ngIf="notification.detail.detail_table">
      <ul>
        <li *ngIf="notification.detail.detail_table.action_reload_sheet">
          <bdr-button class="btn-save" primary (click)="onCancelSheetReload()"
            >No Lanzar</bdr-button
          >
        </li>
        <li>
          <bdr-button class="btn-save" primary (click)="onTableToCSV()">Descargar CSV</bdr-button>
        </li>
        <li *ngIf="notification.detail.detail_table.action_reload_sheet">
          <bdr-button class="btn-save" primary (click)="onSheetReload()">Lanzar</bdr-button>
        </li>
      </ul>
    </footer>
  </div>
</section>
