<div class="validations-summary">
    <div class="panel-header">
        <span class="title">
            <ng-content></ng-content>
        </span>
        <div class="panel-header-cancel">
            <bdr-icon icon="ico-close" size="16px" (click)="closePanel()"></bdr-icon>
        </div>
    </div>
    <div class="panel-body">
        <div class="tab-headers">
            <a
                class="tab-header"
                [ngClass]="{ selected: selectedTab === 'automatic' }"
                (click)="selectedTab = 'automatic'"
                >Automáticas</a
            >
        </div>
        <div class="tab-body automatic-validations" *ngIf="selectedTab === 'automatic'">
            <bdr-validations [origin]="origin"> </bdr-validations>
        </div>
       
    </div>
</div>
