<aside class="side-panel compressed">
    <div class="side-panel-items">
        <div
            *ngIf="panels && panels.downloads"
            [ngClass]="{ 'side-panel-item': true, active: downloadsPanelVisible }"
            (click)="originTableName ? switchPanels('downloadsPanelVisible', downloadsPanelVisible) : null"
        >
            <bdr-icon
                class="side-panel-item-icon"
                [ngClass]="{ disabled: !originTableName }"
                icon="ico-download"
            ></bdr-icon>
            <span *ngIf="originTableName" class="side-panel-item-text">Descargar Datos</span>
        </div>

        <div
            *ngIf="!!panels && !!panels.exportF9Form && originTableName && originTableName.includes('f9')"
            [ngClass]="{
        'side-panel-item': true,
        disabled: !!panels.exportF9Form.disabled
      }"
            (click)="originTableName && panels.exportF9Form.disabled === false ? exportF9() : null"
        >
            <bdr-icon
                class="side-panel-item-icon"
                [ngClass]="{ disabled: !!panels.exportF9Form.disabled }"
                icon="ico-formularios"
            >
            </bdr-icon>
            <span *ngIf="originTableName" class="side-panel-item-text">Exportar formulario</span>
        </div>

        <div
            *ngIf="panels && panels.exportCNMC && originTableName && originTableName.includes('fb')"
            [ngClass]="{ 'side-panel-item': true, active: exportCNMCPanelVisible }"
            (click)="originTableName ? switchPanels('exportCNMCPanelVisible', exportCNMCPanelVisible) : null"
        >
            <bdr-icon
                class="side-panel-item-icon"
                [ngClass]="{ disabled: !originTableName }"
                icon="ico-formularios"
            ></bdr-icon>
            <span *ngIf="originTableName" class="side-panel-item-text">Exportar CNMC</span>
        </div>

        <div
            *ngIf="panels && panels.exportCNMC && originTableName && originTableName.includes('fb')"
            [ngClass]="{ 'side-panel-item': true, active: logsCNMCPanelVisible }"
            (click)="originTableName ? switchPanels('logsCNMCPanelVisible', logsCNMCPanelVisible) : null"
        >
            <bdr-icon
                class="side-panel-item-icon"
                [ngClass]="{ disabled: !originTableName }"
                icon="ico-ok"
            ></bdr-icon>
            <span *ngIf="originTableName" class="side-panel-item-text">Validaciones CNMC</span>
        </div>

        <div
            *ngIf="panels && panels.exportCNMC && originTableName && originTableName.includes('fb')"
            [ngClass]="{ 'side-panel-item': true, active: reloadLogsCNMCPanelVisible }"
            (click)="originTableName ? switchPanels('reloadLogsCNMCPanelVisible', reloadLogsCNMCPanelVisible) : null"
        >
            <bdr-icon
                class="side-panel-item-icon"
                [ngClass]="{ disabled: !originTableName }"
                icon="ico-process"
            ></bdr-icon>
            <span *ngIf="originTableName" class="side-panel-item-text"
                >Calcular Validaciones CNMC</span
            >
        </div>

        <div
            *ngIf="panels && panels.fastFilter"
            [ngClass]="{ 'side-panel-item': true, active: columnsSearchVisible }"
            (click)="
        originTableName && !panels.fastFilter.disabled
          ? toggleColumnSearch(!columnsSearchVisible)
          : null
      "
        >
            <bdr-icon
                class="side-panel-item-icon"
                [ngClass]="{ disabled: !originTableName }"
                icon="ico-fastfilter"
            >
            </bdr-icon>
            <span *ngIf="originTableName" class="side-panel-item-text">Filtrar datos</span>
        </div>

        <div
            *ngIf="
            this.validationConfig != undefined
      "
            [ngClass]="{ 'side-panel-item': true, active: validationsPanelVisible }"
            (click)="
                originTableName ? switchPanels('validationsPanelVisible', validationsPanelVisible) : null
      "
        >
            <bdr-icon
                class="side-panel-item-icon"
                [ngClass]="{ disabled: !originTableName }"
                icon="ico-validations"
            >
            </bdr-icon>
            <span class="side-panel-item-text">Validaciones</span>
        </div>

        <div
            *ngIf="panels && panels.filters"
            [ngClass]="{ 'side-panel-item': true, active: filtersPanelVisible }"
            (click)="
        originTableName && !panels.filters.disabled
          ? switchPanels('filtersPanelVisible', filtersPanelVisible)
          : null
      "
        >
            <bdr-icon
                class="side-panel-item-icon"
                [ngClass]="{ disabled: !originTableName }"
                icon="ico-filter"
            ></bdr-icon>
            <span *ngIf="originTableName" class="side-panel-item-text">Filtros</span>
        </div>

        <div
            *ngIf="panels && (scope === 'inventory' || scope === 'forms') && !disabledVisualization"
            [ngClass]="{ 'side-panel-item': true, active: massEditPanelVisible }"
            (click)=" originTableName ? massEdition(): null"
        >
            <bdr-icon
                class="side-panel-item-icon"
                [ngClass]="{ disabled: !originTableName }"
                icon="ico-edit"
            >
            </bdr-icon>
            <span *ngIf="originTableName" class="side-panel-item-text">Edición masiva</span>
        </div>

        <div
            *ngIf="panels && panels.visualization"
            [ngClass]="{ 'side-panel-item': true, active: visualizationPanelVisible }"
            (click)="originTableName ? showVisualization() : null"
        >
            <bdr-icon
                class="side-panel-item-icon"
                [ngClass]="{ disabled: !originTableName }"
                icon="ico-eye"
            ></bdr-icon>
            <span *ngIf="originTableName" class="side-panel-item-text">Visualizar datos</span>
        </div>

        <div
            *ngIf="!!panels && !!panels.refreshSheet"
            [ngClass]="{
        'side-panel-item': true,
        active: refreshSheetPanelVisible
      }"
            (click)="
        enabledReloadButton
          ? switchPanels('refreshSheetPanelVisible', refreshSheetPanelVisible, $event)
          : null
      "
            [title]="disabledReloadButtonReason"
        >
            <bdr-icon
                class="side-panel-item-icon"
                [ngClass]="{ disabled: !originTableName || !enabledReloadButton }"
                icon="ico-update"
            ></bdr-icon>
            <div *ngIf="originTableName && enabledReloadButton" class="side-panel-item-text">
                Actualizar sábana
            </div>
        </div>

        <div
            *ngIf="
      !!panels
      && (scope === 'investments' || scope === 'installations' &&  originTableName == 'sabana_informe_instalaciones_despachos_detalle_vista')
      && !!panels.refreshSheet
      "
            [ngClass]="{ 'side-panel-item': true, active: downloadsPanelVisible}"
            (click)="
        enabledMassEditButton
        ? showMassEdit()
        : null
    "
            [title]="disabledMassEditButtonReason"
        >
            <bdr-icon
                class="side-panel-item-icon"
                [ngClass]="{ disabled: !originTableName || !enabledMassEditButton }"
                icon="ico-edit"
            ></bdr-icon>
            <div *ngIf="originTableName && enabledMassEditButton" class="side-panel-item-text">
                Edición masiva
            </div>
        </div>

        <div
            *ngIf="
        panels &&
        originTableName &&
        originTableName.includes('_auditoria_') &&
        !closedAuditory
      "
            [ngClass]="{
        'side-panel-item': true,
        active: false
      }"
            (click)="closeAuditory()"
        >
            <bdr-icon class="side-panel-item-icon" icon="ico-unlock"></bdr-icon>
            <div class="side-panel-item-text">Cerrar auditoría</div>
        </div>

        <div
            *ngIf="
        panels &&
        originTableName &&
        originTableName.includes('_auditoria_') &&
        closedAuditory
      "
            [ngClass]="{
        'side-panel-item': true,
        active: false
      }"
            (click)="openAuditory()"
        >
            <bdr-icon class="side-panel-item-icon" icon="ico-lock"></bdr-icon>
            <div class="side-panel-item-text">Abrir auditoría</div>
        </div>
    </div>
</aside>

<div class="panels-wrapper compressed">
    <div *ngIf="panels && panels.downloads && downloadsPanelVisible" class="panel-wrapper overGrid">
        <bdr-download-options
            [appliedFilters]="appliedFilters"
            [exportData]="exportData"
            [visibleColumns]="visibleColumns"
            [allColumns]="allColumns"
            (close)="togglePanel('downloadsPanelVisible', downloadsPanelVisible)"
            >Descargar datos
        </bdr-download-options>
    </div>

    <div
        *ngIf="panels && panels.exportCNMC && exportCNMCPanelVisible"
        class="panel-wrapper overGrid"
    >
        <bdr-export-cnmc-options
            [appliedFilters]="appliedFilters"
            [exportData]="exportData"
            [visibleColumns]="visibleColumns"
            [allColumns]="allColumns"
            (close)="togglePanel('exportCNMCPanelVisible', exportCNMCPanelVisible)"
        >
            Exportar CNMC
        </bdr-export-cnmc-options>
    </div>

    <div *ngIf="panels && panels.exportCNMC && logsCNMCPanelVisible" class="panel-wrapper overGrid">
        <bdr-logs-cnmc-options
            [appliedFilters]="appliedFilters"
            [exportData]="exportData"
            (close)="togglePanel('logsCNMCPanelVisible', logsCNMCPanelVisible)"
        >
            Validaciones CNMC
        </bdr-logs-cnmc-options>
    </div>

    <div
        *ngIf="panels && panels.exportCNMC && reloadLogsCNMCPanelVisible"
        class="panel-wrapper overGrid"
    >
        <bdr-reload-logs-cnmc-options
            [exportData]="exportData"
            (close)="togglePanel('reloadLogsCNMCPanelVisible', reloadLogsCNMCPanelVisible)"
        >
            Calcular Validaciones CNMC
        </bdr-reload-logs-cnmc-options>
    </div>

    <div
        *ngIf="panels && panels.validations && validationsPanelVisible"
        class="panel-wrapper overGrid"
        [ngClass]="{ hidden: !validationsPanelVisible }"
    >
        <bdr-validations-summary
            (close)="togglePanel('validationsPanelVisible', validationsPanelVisible)"
            (validationsDetail)="validationsDetail($event)"
            [filters]="filters"
            [validationsScope]="validationsScope"
            [showManualData]="panels.manualValidations !== undefined ? panels.manualValidations : true"
            (manualValidationsClick)="manualValidationsClickAction($event)"
            [origin]="originTableName"
            >Validaciones
        </bdr-validations-summary>
    </div>

    <div
        *ngIf="panels && panels.filters"
        class="panel-wrapper overGrid"
        [ngClass]="{ hidden: !filtersPanelVisible }"
    >
        <bdr-filter-list
            [appliedFilters]="appliedFilters"
            [type]="'filter'"
            [origin]="originTableName"
            (close)="togglePanel('filtersPanelVisible', filtersPanelVisible)"
            (loadItemRequest)="newItem(panelTypes.FILTER, $event)"
            (newItemRequest)="newItem(panelTypes.FILTER)"
            (applyItemRequest)="applyFilter($event)"
            (addToFolderRequest)="addFilterToFolder($event)"
            (showConfirm)="showConfirmAction($event)"
            >Filtros Avanzados</bdr-filter-list
        >
    </div>

    <div
        *ngIf="panels && panels.refreshSheet && refreshSheetPanelVisible"
        class="panel-wrapper overGrid refreshSheetMenu"
    >
        <bdr-reload-options
            [origin]="getOrigin()"
            (launchReload)="refreshSheet($event)"
            (close)="togglePanel('refreshSheetPanelVisible', refreshSheetPanelVisible)"
        >
        </bdr-reload-options>
    </div>
</div>
