import { Injectable } from '@angular/core';
import { DataApiService } from '../data-api/data-api.service';

@Injectable()
export class UserPermissionsService {
  private currentPermissions = {};

  constructor(private apiService: DataApiService) {}

  get canCheckoutAllWorks() {
    return this.can('checkout_all_works');
  }

  get canReloadGDA() {
    return this.can('reload_gda');
  }

  sync(userProfile) {
    this.apiService.getUserPermissions(userProfile).subscribe((response) => {
      this.currentPermissions = response;
    });
  }

  can(featureName: string) {
    return !!this.currentPermissions[featureName];
  }
}
