import { Subject } from 'rxjs';
import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import { AuthService, UserProfile } from './services/auth/auth.service';
import { Notification, NotificationsService } from './services/notifications/notifications.service';
import { BdrContextualMenuService } from './services/bdr-contextual-menu/bdr-contextual-menu.service';
import { SchemaService } from './services/schema/schema.service';
import { MastersService } from './services/masters/masters.service';
import { FilterService } from './services/filter/filter.service';
import { routes } from './app.routing';
import { VERSION } from '../environments/version';
import { DownloadsService } from './services/downloads/downloads.service';
import { DataApiService } from './services/data-api/data-api.service';
import { WindowService } from './services/window/window.service';
import { OriginService } from './services/origin/origin.service';
import { AsyncQueryResponse } from './models/query-poller';
import { filter, map } from 'rxjs/operators';
import { TasksService } from './services/tasks/tasks.service';
import { DialogEtls } from './containers/dialog/dialog-etls/dialog-etls.component';
import { DialogService } from './containers/dialog/dialog.service';
import { UserPermissionsService } from './services/user-permissions/user-permissions.service';
import { DialogUnlockWorks } from './containers/dialog/dialog-unlock-works/dialog-unlock-works.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  totalRouteCollection: any[];
  userProfile: UserProfile;
  currentTasks: any[];

  notifications: Notification[] = [];
  popupNotifications: Notification[] = [];

  taskNotificator;
  authenticated: boolean;
  showNotifications: boolean;
  retributiveYear: string;
  version: any;
  isMasterSelected: boolean;

  // TODO - Implementar servicio de titulo
  constructor(
    public auth: AuthService,
    private router: Router,
    private notificationsService: NotificationsService,
    private tasksService: TasksService,
    public contextualMenuService: BdrContextualMenuService,
    private mastersService: MastersService,
    private filterService: FilterService,
    private schemaService: SchemaService,
    private ref: ChangeDetectorRef,
    private downloadsService: DownloadsService,
    private dataApiService: DataApiService,
    private windowService: WindowService,
    private originService: OriginService,
    private dialogService: DialogService,
    private userPermissionsService: UserPermissionsService,
  ) {
    this.version = VERSION;
    this.currentTasks = [];
    this.userProfile = null;
    this.totalRouteCollection = routes;
    this.showNotifications = false;
  }

  async ngOnInit() {
    this.handleRouteMasterSelected();

    this.auth.getAuthenticated().subscribe((authenticated) => {
      this.authenticated = authenticated;
      if (this.windowService.getLocationHref().includes('/login') && !!this.authenticated) {
        this.router.navigate(['/']);
      }
      if (!authenticated) {
        this.destroy();
      }
    });
    this.authenticated = await this.auth.isAuthenticated();

    this.auth.initProfile();
    this.auth.getProfile().subscribe((profile) => {
      this.userProfile = profile;

      if (!profile) {
        let key = 'recoveryCode';
        if (location.href.includes(key)) {
          this.auth.recoveryCode = document.URL.slice(document.URL.indexOf(key) + key.length + 1);
        }
        if (!this.windowService.getLocationHref().includes('/login')) {
          this.router.navigate(['/login']);
        }
        return;
      }

      this.dataApiService.loginLog(this.userProfile).subscribe();
      this.userPermissionsService.sync(this.userProfile);

      // Masters fetch
      this.subscribeItems();
      this.mastersService.fetchMasters();
      let userFiltersOrigin = this.originService.getByKey('user_filters');
      this.filterService.get(userFiltersOrigin);
      this.schemaService.fetchSchemas();
      // Routes
      this.checkForActiveTasks();
    });
    this.initDownloads();
  }

  checkForActiveTasks() {
    this.taskNotificator = this.tasksService.getTasksObservable();
    this.taskNotificator.subscribe((taskValue) => {
      if (taskValue.length) {
        this.currentTasks = taskValue.slice(0);
        this.ref.detectChanges();
      } else if (this.currentTasks.length > 0) {
        this.currentTasks = [];
        this.ref.detectChanges();
      }
    });
  }

  initDownloads() {
    let downloadsList = JSON.parse(localStorage.getItem('BDR_DOWNLOADS_LIST')) || [];
    downloadsList.forEach((download) => {
      this.downloadsService.addDownload(download);
      let jobSubject = new Subject<AsyncQueryResponse>();
      let poller = this.dataApiService.jobQueryPoller(download.id);
      poller.getObservable().subscribe((pollerResponse: AsyncQueryResponse) => {
        poller.complete();
        this.downloadsService.assignData(pollerResponse, download.id);
        jobSubject.next(pollerResponse);
        return;
      });
      return jobSubject.asObservable();
    });
  }

  private subscribeItems(): void {
    this.notificationsService.getNotificationObservable().subscribe((items: Notification[]) => {
      this.popupNotifications = items.filter(
        (notification: Notification) => notification.popup && !notification.read,
      );
    });

    this.mastersService.getRetributiveYear().subscribe((year) => {
      // Avoid redirect when the year is loaded "for the very first time" (LOL)
      if (!!this.retributiveYear && year !== this.retributiveYear) {
        this.mastersService.fetchMasters();
        this.router.navigate(['/']);
      }
      this.retributiveYear = year;
    });
  }

  destroy() {
    this.tasksService.destroy();
  }

  closeLoadingOverlay() {
    var overlay = document.getElementsByClassName('app-loading-overlay')[0];
    overlay.parentNode.removeChild(overlay);
  }

  handleRouteMasterSelected() {
    this.router.events
      .pipe(
        filter((evt) => evt instanceof NavigationEnd),
        map((evt: NavigationEnd) => evt.url === '/settings/master-data'),
      )
      .subscribe((evt) => (this.isMasterSelected = evt));
  }

  showSheetReloadDialog() {
    const dialogOptions: any = {
      id: 'etls-dialog',
      title: `Recarga de sábanas ${this.retributiveYear}`,
      size: { width: 10, height: 10 },
      component: DialogEtls,
      fixed: true,
      notHeader: true,
      headerComponent: true,
      bindings: {
        inputs: {
          title: `Recarga de sábanas ${this.retributiveYear}`,
        },
        outputs: {
          onCloseAction: this.dialogService.closeDialog.bind(this.dialogService),
        },
      },
    };
    this.dialogService.createDialog(dialogOptions);
  }

  showUnlockWorksDialog() {
    const dialogOptions: any = {
      id: 'unlock-works-dialog',
      title: `Desbloquer obras de ${this.retributiveYear}`,
      size: { width: 10, height: 10 },
      component: DialogUnlockWorks,
      fixed: true,
      notHeader: true,
      headerComponent: true,
      bindings: {
        inputs: {
          title: `Desbloquer obras de ${this.retributiveYear}`,
          year: this.retributiveYear,
        },
        outputs: {
          onCloseAction: this.dialogService.closeDialog.bind(this.dialogService),
        },
      },
    };
    this.dialogService.createDialog(dialogOptions);
  }
}
